import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { ChromeHomeBoardConfigMenu } from "./ChromeHomeBoardConfigMenu";
import { ChromeSearchInput } from "./ChromeSearchInput";
import { ChromeShortcut } from "./ChromeShortcut";
import { ChromeTopSitesPermission } from "./ChromeTopSitesPermission";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Container = styled.div`
  --home-board-width: 510px;
  --home-board-topSites-permission-width: 413px;
  margin-bottom: var(--header-margin);

  @media (max-width: 768px) {
    display: none;
  }
`;

const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 var(--page-padding);
`;

const FeatureSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: var(--home-board-width);
  padding: 32px 0;
  margin: 0 auto;
`;

const ConfigSection = styled.section`
  display: flex;
  justify-content: flex-end;
`;

export type Config = {
  inquery_topSites_permission: boolean;
  has_topSites_permission: boolean;
  use_chrome_shortcut: boolean;
  use_chrome_search: boolean;
};

export function ChromeHomeBoard() {
  const router = useRouter();

  /**
   * config
   * inquery_topSites_permission : 권한을 물어봤는지
   * has_topSites_permission : 권한이 있는지
   * use_chrome_shortcut : 사이트 바로가기 사용 여부
   * use_chrome_search : 구글 검색 사용 여부
   */
  const [config, setConfig] = useState<Config>({
    inquery_topSites_permission: false,
    has_topSites_permission: false,
    use_chrome_shortcut: true,
    use_chrome_search: true,
  });

  const [configLoading, setConfigLoading] = useState(true);

  const showChromeSearch = config.use_chrome_search;

  const showChromeShortcut =
    config.has_topSites_permission && config.use_chrome_shortcut;

  /**
   * TopSites 권한 질의 결과 제출
   */
  const submitTopSitesPermission = (granted: boolean) => {
    const newConfig: Config = {
      inquery_topSites_permission: true,
      has_topSites_permission: granted,
      use_chrome_shortcut: granted,
      use_chrome_search: config.use_chrome_search,
    };

    setConfig(newConfig);
    chrome.storage.local.set(newConfig);
  };

  /**
   * 크롬 스토리지 값 불러오기
   * use_chrome_shortcut, use_chrome_search의 기본값은 true 이므로, inquery_topSites_permission를 기점으로 스토리지 값을 사용합니다.
   */
  useEffect(() => {
    (async () => {
      const { inquery_topSites_permission } = await chrome.storage.local.get(
        "inquery_topSites_permission",
      );

      const { has_topSites_permission } = await chrome.storage.local.get(
        "has_topSites_permission",
      );

      const { use_chrome_shortcut } = await chrome.storage.local.get(
        "use_chrome_shortcut",
      );

      const { use_chrome_search } = await chrome.storage.local.get(
        "use_chrome_search",
      );

      const newConfig: Config = {
        inquery_topSites_permission,
        has_topSites_permission,
        use_chrome_shortcut: inquery_topSites_permission
          ? use_chrome_shortcut
          : true,
        use_chrome_search: inquery_topSites_permission
          ? use_chrome_search
          : true,
      };

      setConfig(newConfig);
      setConfigLoading(false);
    })();
  }, []);

  if (configLoading)
    return (
      <div
        css={css`
          height: 100vh;
          width: 100vw;
          background: white;
        `}
      />
    );

  return (
    <Container>
      <SectionList>
        {(showChromeSearch || showChromeShortcut) && (
          <FeatureSection>
            {showChromeSearch && <ChromeSearchInput />}
            {!config.inquery_topSites_permission ? (
              <ChromeTopSitesPermission
                onSubmitTopSitesPermission={submitTopSitesPermission}
              />
            ) : (
              showChromeShortcut && <ChromeShortcut />
            )}
          </FeatureSection>
        )}
        <ConfigSection>
          <ChromeHomeBoardConfigMenu
            config={config}
            onChangeConfig={(config) => {
              setConfig(config);
              chrome.storage.local.set(config);
            }}
            onSubmitTopSitesPermission={submitTopSitesPermission}
          />
        </ConfigSection>
      </SectionList>
    </Container>
  );
}
