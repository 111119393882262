import React from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface DndItemProps {
  animateLayoutChanges?: AnimateLayoutChanges;
  id: UniqueIdentifier;
  children: React.ReactNode;
  dragging?: boolean;
  renderItem?(args: {
    dragging: boolean;
    ref: React.RefObject<HTMLElement>;
    style: React.CSSProperties | undefined;
  }): React.ReactElement;
}

const DndItem = React.forwardRef<HTMLLIElement, DndItemProps>(
  ({ animateLayoutChanges, id, dragging, renderItem, children }, ref) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id, animateLayoutChanges });
    const style: React.CSSProperties = {
      width: "fit-content",
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return renderItem ? (
      renderItem({
        dragging: Boolean(dragging),
        ref: ref as React.RefObject<HTMLDivElement>,
        style,
      })
    ) : (
      <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {children}
      </li>
    );
  },
);

DndItem.displayName = "DndItem";

export { DndItem };
