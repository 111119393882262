import { ComponentProps, FormEvent, useRef } from "react";
import { useRouter } from "next/router";
import { TextField } from "./TextField";
import { fireEvent } from "lib/ga";

export function SearchTextField({
  value,
  ...props
}: Omit<ComponentProps<typeof TextField>, "variant" | "startItem">) {
  const router = useRouter();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (value !== "") {
      fireEvent({
        event: "content_search",
        params: {
          text: value as string,
        },
      });

      if (process.env.BUILD_TARGET === "extension") {
        router.push({
          protocol: "https",
          host: "mix.day",
          pathname: "/search",
          query: {
            text: value,
          },
        });
      } else {
        router.push({
          pathname: "/search",
          query: {
            text: value,
          },
        });
      }
    }
  };

  const handleSearchClick = () => {
    if (formRef.current) {
      const submitEvent = new Event("submit", {
        bubbles: true,
        cancelable: true,
      });
      formRef.current.dispatchEvent(submitEvent);
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <TextField
        size="medium"
        variant="filled"
        placeholder="콘텐츠 검색"
        value={value}
        endItem={
          <TextField.Icon
            name="SearchFilled"
            onClick={handleSearchClick}
            style={{ cursor: "pointer" }}
          />
        }
        {...props}
      />
    </form>
  );
}
