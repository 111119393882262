import { useRouter } from "next/router";
import { Interpolation, Theme, css } from "@emotion/react";
import styled from "@emotion/styled";
import { ExtensionLink } from "components/core/ExtensionLink";
import { ToggleButton } from "components/core/buttons/ToggleButton";
import { fireEvent } from "lib/ga";
import { AppPage } from "models/AppPage";
import {
  MIX_CONTENT_TAG_ENUM_KOREAN,
  ORDERED_MIX_CONTENT_TAG_ENUM,
} from "utils/enum";

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  button {
    width: 100%;
  }
`;

const SubMenuList = styled.div`
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
`;

const SubMenu = styled.div<{ focused: boolean }>`
  ${({ theme, focused }) => css`
    ${theme.typography.body3};
    font-weight: ${focused ? "700" : "400"};
    color: ${focused
      ? theme.palette.secondary.main
      : theme.palette.text.disabled};
    padding: 2px 0 2px 48px;
    cursor: pointer;

    &:hover {
      color: ${theme.palette.secondary.main};
    }
  `}
`;

const pages: Array<AppPage> = [
  new AppPage({
    href: "/",
    label: "콘텐츠 큐레이션",
    external: false,
    iconName: "All",
    children: ORDERED_MIX_CONTENT_TAG_ENUM.map(
      (value) =>
        new AppPage({
          href: `/?tag=${value}`,
          label: MIX_CONTENT_TAG_ENUM_KOREAN[value],
          external: false,
        }),
    ),
  }),
  new AppPage({
    href: "/original",
    label: "오리지널 콘텐츠",
    external: false,
    iconName: "Original",
  }),
  new AppPage({
    href: "/bookmark",
    label: "북마크",
    external: false,
    iconName: "BookmarkFilled",
  }),
  new AppPage({
    href: "https://support.mix.day",
    label: "고객센터",
    external: true,
    iconName: "InfoFilled",
  }),
];

const pagesInMypage: Array<AppPage> = [
  new AppPage({
    href: "/mypage",
    label: "나의 정보",
    external: false,
    iconName: "AccountCircle",
  }),
  new AppPage({
    href: "/mypage/orders",
    label: "나의 주문 내역",
    external: false,
    iconName: "List",
  }),
];

export const NavMenu = ({
  showSubMenu,
  ...props
}: {
  showSubMenu: boolean;
  css?: Interpolation<Theme>;
}) => {
  const router = useRouter();

  const currentPages = router.pathname.startsWith("/mypage")
    ? pagesInMypage
    : pages;

  return (
    <MenuList {...props}>
      {currentPages.map((page) => (
        <>
          {page.external ? (
            <a key={page.href} href={page.href}>
              <ToggleButton
                name={page.iconName}
                active={page.active(router.pathname)}
              >
                {page.label}
              </ToggleButton>
            </a>
          ) : (
            <ExtensionLink key={page.href} href={page.href}>
              <ToggleButton
                name={page.iconName}
                active={page.active(router.pathname)}
              >
                {page.label}
              </ToggleButton>
            </ExtensionLink>
          )}
          {showSubMenu && page.children && page.children.length > 0 && (
            <SubMenuList
              css={css`
                display: flex;
                overflow: hidden;

                ${page.active(router.pathname)
                  ? css`
                      max-height: ${page.children.length * 30}px;
                      transition: all 0.2s ease-in;
                    `
                  : css`
                      max-height: 0;
                      transition: all 0.2s ease-out;
                      padding: 0;
                    `}
              `}
            >
              {page.children.map((child) => (
                <ExtensionLink key={child.href} href={child.href}>
                  <SubMenu
                    focused={child.active(router.asPath)}
                    onClick={() => {
                      fireEvent({
                        event: "content_category_click",
                        params: {
                          value: child.label,
                        },
                      });
                    }}
                  >
                    {child.label}
                  </SubMenu>
                </ExtensionLink>
              ))}
            </SubMenuList>
          )}
        </>
      ))}
    </MenuList>
  );
};
