import {
  ComponentProps,
  DetailedHTMLProps,
  InputHTMLAttributes,
  useRef,
} from "react";
import { IconBox } from "../IconBox";
import { BaseFormBox, BaseFormBoxProps } from "./BaseFormBox";
import { css, useTheme } from "@emotion/react";

type TextFieldProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "size"
> &
  BaseFormBoxProps & {
    startItem?: React.ReactNode;
    endItem?: React.ReactNode;
    hideClearButton?: boolean;
  };

export function TextField({
  style,
  className,
  size,
  interaction,
  variant,
  disabled,
  error,
  startItem,
  endItem,
  hideClearButton,
  ...props
}: TextFieldProps) {
  const theme = useTheme();
  const localRef = useRef<HTMLInputElement>(null);

  return (
    <BaseFormBox
      css={css`
        display: flex;
        align-items: center;
        gap: 4px;

        &:has(input:not(:placeholder-shown)) {
          > [data-close-icon] {
            display: initial;
          }
        }
      `}
      style={style}
      className={className}
      size={size}
      variant={variant}
      interaction={interaction}
      disabled={disabled}
      error={error}
    >
      {startItem}
      <input
        ref={localRef}
        css={css`
          width: 100%;
          flex: 1;
          border: none;
          outline: none;
          background-color: transparent;
          ${theme.typography.input.value};

          &::placeholder {
            color: ${theme.palette.text.disabled};
          }

          // number type - hide the arrow
          &[type="number"]::-webkit-inner-spin-button,
          &[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type="number"] {
            -moz-appearance: textfield;
          }
        `}
        disabled={disabled}
        placeholder={props.placeholder || ""} // for :placehoder-shown works
        {...props}
      />
      {!disabled && !hideClearButton && (
        <IconBox
          css={css`
            display: none;
            cursor: pointer;
          `}
          name="Close"
          size="medium"
          color={theme.palette.grey[400]}
          data-close-icon
          onClick={() => {
            const target = localRef.current;
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
              window.HTMLInputElement.prototype,
              "value",
            )?.set;

            if (target && nativeInputValueSetter) {
              nativeInputValueSetter.call(target, "");
              target.dispatchEvent(new Event("input", { bubbles: true }));
            }
          }}
        />
      )}
      {endItem}
    </BaseFormBox>
  );
}

const Icon = (props: ComponentProps<typeof IconBox>) => {
  return <IconBox size="large" {...props} />;
};

TextField.Icon = Icon;
