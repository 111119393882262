import { ReactElement, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  AnimateLayoutChanges,
  SortableContext,
  arrayMove,
  defaultAnimateLayoutChanges,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { DndItem } from "components/core/dnd/DndItem";

export const DndContainer = ({
  renderItems,
  animateLayoutChanges = defaultAnimateLayoutChanges,
  onDragEnd,
}: {
  renderItems: ReactElement[];
  animateLayoutChanges?: AnimateLayoutChanges;
  onDragEnd?: (uniqueIds: Array<UniqueIdentifier>) => void;
}) => {
  const [items, setItems] = useState<Array<UniqueIdentifier>>(
    renderItems.map((item) => item.key as UniqueIdentifier),
  );

  useEffect(() => {
    setItems(renderItems.map((item) => item.key as UniqueIdentifier));
  }, [renderItems]);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 0.1 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && over.id !== active.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);

      if (onDragEnd) {
        onDragEnd(newItems);
      }
    }
  };

  const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({}),
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {items.map((item) => (
          <DndItem
            key={item}
            id={item}
            animateLayoutChanges={animateLayoutChanges}
          >
            {renderItems.find((renderItem) => renderItem.key === item)}
          </DndItem>
        ))}
      </SortableContext>

      {createPortal(
        <DragOverlay dropAnimation={dropAnimationConfig}></DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};
