import { TilerailBanner } from "./TilerailBanner";
import { css } from "@emotion/react";
import ChromeBanner from "components/domain/chrome-extension/ChromeBanner";
import { useAuth } from "hooks/useAuth";

export function DesktopMainBanner() {
  const { me } = useAuth();

  return !me ? (
    <div
      css={css`
        padding: 0 var(--page-padding);
        margin-bottom: 64px;

        @media (max-width: 768px) {
          display: none;
        }
      `}
    >
      <ChromeBanner />
    </div>
  ) : null;
}

export function NonDesktopMainBanner() {
  return (
    <div
      css={css`
        display: none;

        @media (max-width: 768px) {
          display: flex;
          padding: 0 var(--page-padding);
          margin-bottom: 24px;
        }
      `}
    >
      <TilerailBanner />
    </div>
  );
}

export function MainBanner() {
  return (
    <>
      <DesktopMainBanner />
      <NonDesktopMainBanner />
    </>
  );
}
