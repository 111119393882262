import {
  ClassAttributes,
  ComponentProps,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import Image from "next/image";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import { IconBox } from "components/core/IconBox";
import { Menu } from "components/core/Menu";
import { MenuItem } from "components/core/MenuItem";
import { MenuList } from "components/core/MenuList";
import { Text } from "components/core/Text";
import { Button } from "components/core/buttons/Button";
import { DndContainer } from "components/core/dnd/DndContainer";
import { TextField } from "components/core/inputs/TextField";
import { MixModal } from "components/core/modals/MixModal";
import { useChromeStorage } from "hooks/useChromeStorage";

export const DEFAULT_SITE_LENGTH = 9;

export function ChromeShortcut() {
  const theme = useTheme();

  const [topSites, setTopSites] = useChromeStorage<
    Array<chrome.topSites.MostVisitedURL>
  >("topSites", [], "local");

  const [selectedSite, setSelectedSite] =
    useState<chrome.topSites.MostVisitedURL | null>(null);

  const [siteEditModalOpen, setSiteEditModalOpen] = useState(false);

  /**
   * Chrome TopSites API
   *
   * chrome.storage에 저장된 값이 있으면 그대로 사용하고, 없으면 API를 호출합니다.
   * API 호출 결과를 chrome.storage에 최대 DEAFULT_SITE_LENGTH 개 저장합니다.
   */
  useEffect(() => {
    (async () => {
      const storage = await chrome.storage.local.get("topSites");

      if (storage.topSites) {
        setTopSites(storage.topSites);
      } else {
        const result = await chrome.topSites.get();
        setTopSites(result.slice(0, DEFAULT_SITE_LENGTH));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSiteChange = (site: chrome.topSites.MostVisitedURL) => {
    if (selectedSite) {
      const newTopSites = topSites.map((topSite) =>
        topSite.url === selectedSite.url ? site : topSite,
      );
      setTopSites(newTopSites);
    } else {
      const newTopSites = [...topSites, site];
      setTopSites(newTopSites);
    }
  };

  const handleDragEnd = (changedSitesUrlOrder: Array<UniqueIdentifier>) => {
    const newTopSites = changedSitesUrlOrder.map((url) =>
      topSites.find((site) => site.url === url),
    );

    chrome.storage.local.set({ topSites: newTopSites });
  };

  return (
    <ul
      css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
      `}
    >
      {topSites.length > 0 && (
        <DndContainer
          onDragEnd={handleDragEnd}
          renderItems={topSites.map((site) => (
            <SiteCard
              key={site.url}
              title={site.title}
              imgEl={<SiteFavicon site={site} size={24} />}
              menuEl={
                <Menu
                  css={css`
                    min-width: 140px;
                    max-width: 140px;
                    transform: translate(-80%, -40%);
                  `}
                  closeOnClick
                  open={site === selectedSite && !siteEditModalOpen}
                  anchorEl={
                    <div
                      css={css`
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        width: 20px;
                        height: 20px;
                        border-radius: 6px;
                        visibility: hidden;

                        &:hover {
                          background: ${theme.palette.grey[300]};
                          color: ${theme.palette.grey[600]};
                        }
                      `}
                      className="site-more-menu"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedSite(site);
                      }}
                    >
                      <IconBox
                        color={theme.palette.grey[500]}
                        size="small"
                        name="MoreVert"
                      />
                    </div>
                  }
                >
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setSelectedSite(site);
                        setSiteEditModalOpen(true);
                      }}
                    >
                      바로가기 수정
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const newTopSites = topSites.filter(
                          (prevSite) => prevSite.url !== site.url,
                        );

                        setTopSites(newTopSites);
                        setSelectedSite(null);
                      }}
                    >
                      삭제
                    </MenuItem>
                  </MenuList>
                </Menu>
              }
              onClick={() => {
                window.location.href = site.url;
              }}
            />
          ))}
        />
      )}

      <>
        {topSites.length < DEFAULT_SITE_LENGTH + 1 && (
          <SiteCard
            title="바로가기 추가"
            imgEl={<IconBox size="small" name="Add" />}
            onClick={() => setSiteEditModalOpen(true)}
          />
        )}
        {siteEditModalOpen &&
          (selectedSite ? (
            <SiteEditModal
              isOpen={siteEditModalOpen}
              title="바로가기 수정"
              site={selectedSite}
              topSites={topSites}
              onClose={() => {
                setSiteEditModalOpen(false);
                setSelectedSite(null);
              }}
              onSiteChange={handleSiteChange}
            />
          ) : (
            <SiteEditModal
              isOpen={siteEditModalOpen}
              title="바로가기 추가"
              site={null}
              topSites={topSites}
              onClose={() => {
                setSiteEditModalOpen(false);
                setSelectedSite(null);
              }}
              onSiteChange={handleSiteChange}
            />
          ))}
      </>
    </ul>
  );
}

export function SiteCard({
  title,
  imgEl,
  menuEl,
  loading,
  ...props
}: {
  title: string;
  imgEl?: ReactNode;
  menuEl?: ReactNode;
  loading?: boolean;
} & ClassAttributes<HTMLLIElement> &
  HTMLAttributes<HTMLLIElement> & {
    css?: Interpolation<Theme>;
  }) {
  const theme = useTheme();

  return (
    <li
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${theme.shape.radius.medium}px;
        width: calc(var(--home-board-width) / 5 - 2px);
        aspect-ratio: 1 / 1;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background: ${theme.palette.grey[200]};

          .site-more-menu {
            visibility: visible;
          }
        }
      `}
      {...props}
    >
      {menuEl}
      <div
        css={css`
          display: flex;
          width: 48px;
          aspect-ratio: 1 / 1;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          background: ${theme.palette.grey[100]};
        `}
      >
        {imgEl}
      </div>
      <Text
        css={css`
          max-width: 80%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          visibility: ${loading ? "hidden" : "visible"};
        `}
        variant="caption2"
      >
        {title}
      </Text>
    </li>
  );
}

/**
 * Google API로 favicon을 가져올 때, 실패 여부를 체크하는 size인 16 입력을 방지하는 타입
 * @see https://stackoverflow.com/questions/54970570/use-google-favicon-but-dont-get-default-globe-when-no-favicon-exists
 */
type SizeToCheckFaviconError = 16;

function SiteFavicon<T extends number>({
  site,
  size,
}: {
  site: chrome.topSites.MostVisitedURL;
  size: T extends SizeToCheckFaviconError ? never : T;
}) {
  const theme = useTheme();

  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgLoadError, setImgLoadError] = useState(false);

  if (imgLoadError)
    return (
      <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill={theme.palette.grey[400]} />
        <text
          x="50%"
          y="50%"
          dy=".35em"
          textAnchor="middle"
          fontSize={size * 0.6}
          fill={theme.palette.common.white.main}
        >
          {site.title[0]?.toUpperCase() || "-"}
        </text>
      </svg>
    );

  return (
    <Image
      css={css`
        visibility: ${imgLoaded ? "visible" : "hidden"};
      `}
      alt="favicon"
      src={`https://www.google.com/s2/favicons?domain_url=${site.url}&sz=${
        size * 2
      }`}
      width={size}
      height={size}
      onError={() => {
        setImgLoadError(true);
      }}
      onLoad={(event) => {
        setImgLoaded(true);

        const imgElement = event.target as HTMLImageElement;
        if (imgElement.naturalHeight === 16) {
          setImgLoadError(true);
        }
      }}
    />
  );
}

function SiteEditModal({
  title,
  site: $site,
  topSites,
  onClose,
  onSiteChange,
  ...props
}: {
  title: string;
  site: chrome.topSites.MostVisitedURL | null;
  topSites: Array<chrome.topSites.MostVisitedURL>;
  onClose: () => void;
  onSiteChange: (site: chrome.topSites.MostVisitedURL) => void;
} & ComponentProps<typeof MixModal>) {
  const theme = useTheme();

  const [site, setSite] = useState($site ?? { title: "", url: "" });
  const [isError, setIsError] = useState(false);

  const isReady = useMemo(
    () => !!site.title && !!site.url && !isError,
    [site.title, site.url, isError],
  );

  return (
    <MixModal onRequestClose={onClose} {...props}>
      <div
        css={css`
          width: 400px;
          padding: 24px;
          background: ${theme.palette.common.white.main};
          border-radius: 8px;
        `}
      >
        <Text variant="body2" tag="div">
          {title}
        </Text>
        <ul
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${theme.shape.radius.medium}px;
            margin: 16px 0;

            li {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }
          `}
        >
          <li>
            <TextField
              size="large"
              type="text"
              placeholder="이름을 입력해주세요"
              variant="filled"
              value={site.title}
              onChange={(e) =>
                setSite((prev) => ({ ...prev, title: e.target.value }))
              }
            />
          </li>
          <li>
            <TextField
              size="large"
              type="text"
              placeholder="URL을 입력해주세요"
              variant="filled"
              error={isError}
              value={site.url}
              onChange={(e) =>
                setSite((prev) => ({ ...prev, url: e.target.value }))
              }
              onBlur={(e) => {
                const inputUrl = e.target.value;
                const isUniqueUrl = !topSites.some(
                  (topSite) =>
                    topSite.url === inputUrl ||
                    topSite.url === `https://${inputUrl}`,
                );

                if (isUniqueUrl) {
                  setIsError(false);
                } else {
                  setIsError(true);
                }
              }}
            />
            <Text
              css={css`
                margin-left: 10px;
                visibility: ${isError ? "visible" : "hidden"};
              `}
              variant="caption1"
              color="error.main"
            >
              이미 존재하는 URL입니다.
            </Text>
          </li>
        </ul>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              flex: 1;
              gap: ${theme.shape.radius.medium}px;
            `}
          >
            <Button
              size="large"
              color="grey"
              variant="filled"
              onClick={onClose}
            >
              취소
            </Button>
            <Button
              size="large"
              color="primary"
              variant="filled"
              disabled={!isReady}
              onClick={_.flow(
                () =>
                  onSiteChange({
                    ...site,
                    // https:// 또는 http:// 를 붙이지 않은 URL은 https:// 를 붙여줍니다.
                    url:
                      site.url.startsWith("https://") ||
                      site.url.startsWith("http://")
                        ? site.url
                        : `https://${site.url}`,
                  }),
                onClose,
              )}
            >
              확인
            </Button>
          </div>
        </div>
      </div>
    </MixModal>
  );
}
