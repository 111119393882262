import { useEffect } from "react";
import Image from "next/image";
import { UniqueIdentifier } from "@dnd-kit/core";
import { css, useTheme } from "@emotion/react";
import { Menu } from "components/core/Menu";
import { Text } from "components/core/Text";
import { Button } from "components/core/buttons/Button";
import { IconButton } from "components/core/buttons/IconButton";
import { DndContainer } from "components/core/dnd/DndContainer";
import { useChromeStorage } from "hooks/useChromeStorage";
import { GOOGLE_APPS } from "utils/enum";

export function ChromeGoogleAppMenu() {
  const theme = useTheme();

  const [googleAppOrder, setGoogleAppOrder] = useChromeStorage<Array<string>>(
    "googleAppOrder",
    [],
    "local",
  );

  useEffect(() => {
    (async () => {
      const storage = await chrome.storage.local.get("googleAppOrder");

      if (storage.googleAppOrder) {
        setGoogleAppOrder(storage.googleAppOrder);
      } else {
        const defaultOrder = Object.keys(GOOGLE_APPS);
        setGoogleAppOrder(defaultOrder);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragEnd = (changedAppTitleOrder: UniqueIdentifier[]) => {
    chrome.storage.local.set({ googleAppOrder: changedAppTitleOrder });
  };

  return (
    <Menu
      anchorEl={({ open }) => (
        <IconButton
          css={css`
            svg {
              color: ${theme.palette.grey[600]};
            }

            &:hover {
              background-color: ${theme.palette.grey[200]} !important;
            }

            ${open &&
            css`
              background-color: ${theme.palette.grey[200]};
            `}
          `}
          name="GoogleAppButton"
          size="medium"
          variant="background"
          color="grey"
        />
      )}
      placement="bottom-start"
      offset={4}
    >
      <ul
        css={css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          max-height: 400px;
          width: 262px;
          padding: 0 8px;
          overflow-x: hidden;
          overflow-y: scroll;
          overscroll-behavior: contain;
        `}
      >
        <DndContainer
          onDragEnd={handleDragEnd}
          renderItems={googleAppOrder
            .map((name) => {
              const googleApp = GOOGLE_APPS[name];
              return (
                googleApp && (
                  <ChromeGoogleAppMenuItem
                    key={name as UniqueIdentifier}
                    name={name}
                    url={googleApp.url}
                    imgSrc={googleApp.imgSrc}
                  />
                )
              );
            })
            .filter((item): item is React.ReactElement => item !== undefined)}
        />
      </ul>
    </Menu>
  );
}

interface ChromeGoogleAppMenuItemProps {
  key: UniqueIdentifier;
  name: string;
  url: string;
  imgSrc: string;
}

const ChromeGoogleAppMenuItem: React.FC<ChromeGoogleAppMenuItemProps> = ({
  key,
  name,
  url,
  imgSrc,
}) => {
  const theme = useTheme();

  return (
    <Button
      key={key}
      css={css`
        width: 82px;
        height: 88px;
        padding: 8px 0 12px 0;
        border-radius: 12px;

        &:hover,
        &[data-interaction="hover"] {
          background-color: ${theme.palette.grey[100]};
        }
      `}
      variant="background"
      color="secondary"
      onClick={() => {
        window.location.href = `${url}`;
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 82px;
        `}
      >
        <Image
          alt={name}
          src={`/img/google/${imgSrc}`}
          width={50}
          height={50}
          onError={(e) => {
            (e.target as HTMLImageElement).src = "/img/google.png"; // Fallback image
          }}
        />
        <Text variant="body4">{name}</Text>
      </div>
    </Button>
  );
};
