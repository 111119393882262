import { GraphQLErrors } from "@apollo/client/errors";
import { serialize } from "cookie";
import {
  MixUserByRefreshTokenDocument,
  UpdateMeDocument,
} from "graphql/generated";
import jwt from "jsonwebtoken";
import { getClient } from "utils/client";
import {
  RefreshTokenExpiredError,
  RefreshTokenLostError,
  RefreshTokenNotExistsError,
} from "utils/errors";

export const generateAccessToken = (userId: string) => {
  return jwt.sign(
    {
      sub: "user-access-token",
      "https://hasura.io/jwt/claims": {
        "x-hasura-allowed-roles": ["guest", "user"],
        "x-hasura-default-role": "user",
        "x-hasura-user-id": userId,
      },
    } as UserJWTPayload,
    process.env.HASURA_GRAPHQL_JWT_SECRET,
    {
      expiresIn: process.env.NODE_ENV === "production" ? "15m" : "5s",
    },
  );
};

/**
 * @param refreshToken
 * @throws {RefreshTokenNotExistsError} The refresh token is empty.
 * @throws {RefreshTokenLostError} A user who has refresh token is not exists.
 * @throws {RefreshTokenExpiredError} The refresh token is expired.
 * @returns
 */
export const fetchAccessToken = async (refreshToken?: string | string[]) => {
  if (typeof refreshToken !== "string") {
    throw new RefreshTokenNotExistsError("Refresh token is not exists!");
  }

  const client = getClient({
    headers: {
      "x-hasura-admin-secret": process.env.HASURA_ADMIN_SECRET,
    },
    ctx: null,
  });

  const { data } = await client.query<
    MixUserByRefreshTokenQuery,
    MixUserByRefreshTokenQueryVariables
  >({
    query: MixUserByRefreshTokenDocument,
    variables: {
      refreshToken,
    },
  });

  const user = data.mix_user[0];

  if (!user) throw new RefreshTokenLostError("user is not exists!");

  if (
    !user.refreshTokenExpiry ||
    new Date(user.refreshTokenExpiry + "Z") < new Date()
  ) {
    client.mutate<UpdateMeMutation, UpdateMeMutationVariables>({
      mutation: UpdateMeDocument,
      variables: {
        id: user.id,
        _set: {
          refreshToken: null,
          refreshTokenExpiry: null,
        },
      },
    });

    throw new RefreshTokenExpiredError("refresh token is expired!");
  }

  return generateAccessToken(user.id);
};

interface CookieOptions {
  httpOnly: boolean;
  secure: boolean;
  sameSite: "lax" | "strict" | "none";
  domain?: string;
  path: string;
  expires?: Date;
}

const getDefaultCookieOptions = (): Omit<CookieOptions, "expires"> => ({
  httpOnly: true,
  secure: process.env.NODE_ENV === "production",
  sameSite: "lax",
  domain: process.env.NODE_ENV === "production" ? "mix.day" : undefined,
  path: "/",
});

export const buildAccessTokenCookie = (accessToken: string) => {
  return [serialize("accessToken", accessToken, getDefaultCookieOptions())];
};

export const buildRefreshTokenCookie = (
  refreshToken: string,
  refreshTokenExpiry: Date,
) => {
  return [
    serialize("refreshToken", refreshToken, {
      ...getDefaultCookieOptions(),
      expires: refreshTokenExpiry,
    }),
  ];
};

export const buildSignOutCookie = () => {
  return [
    serialize("accessToken", "deleted", {
      ...getDefaultCookieOptions(),
      expires: new Date(0),
    }),
    serialize("refreshToken", "deleted", {
      ...getDefaultCookieOptions(),
      expires: new Date(0),
    }),
  ];
};

export const isHasuraJWTExpired = (error: GraphQLErrors) => {
  return !!error.find((e) => e?.extensions?.code === "invalid-jwt");
};
